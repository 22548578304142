//Force vertical scrollbar (to avoid jumps on window resize)
html {
	overflow-y: scroll;
	scroll-behavior: smooth;
}

//Remove text-shadow in selection highlight: https://twitter.com/miketaylr/status/12228805301
//(These selection rule sets have to be separate.)
::-moz-selection {
	color: white;
	background: $c-riverBed;
	text-shadow: none;
}

::selection {
	color: white;
	background: $c-riverBed;
	text-shadow: none;
}

//Remove the gap between audio, canvas, iframes, images, videos and the bottom of their containers
//https://github.com/h5bp/html5-boilerplate/issues/440
audio,
canvas,
iframe,
img,
svg,
video {
	vertical-align: middle;
}

//Remove default fieldset styles.
fieldset {
	border: 0;
	margin: 0;
	padding: 0;
}

//Allow only vertical resizing of textareas.
textarea {
	resize: vertical;
}

//---------- Base styles ----------
*,
*:after,
*:before {
	box-sizing: border-box;
}

html {
	background-color: $c-nonchalant-white;
	@extend %antialiased;
	text-rendering: optimizeLegibility;
}

body {
	margin: 0 auto;
	overflow-x: hidden;
	overflow-y: hidden;
	background-color: $c-nonchalant-white;
	color: $c-trout;
}

div {
	position: relative;
}

a {
	color: currentColor;
}

i {
	font-style: normal;
}

button {
	border: none;
	background: transparent;
}

img {
	max-width: 100%;
	vertical-align: middle;
	height: auto;
}

/*
object.svgWrapper {
	width: 100%;
	display: block;
	height: auto;
	position: relative;
	padding-top: 100%;

	svg {
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;
		left: 0;
	}
}
*/