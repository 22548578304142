$sidrWidth: 80%;
$sidrMinWidth: 260px;
$sidrMaxWidth: 480px;

//hide the mobile menu html
.mobileNav {
	display: none;
}

.sidr {
	display: none;
	position: absolute;
	position: fixed;
	top: 0;
	height: 100%;
	z-index: 999999;
	width: $sidrWidth;
	min-width: $sidrMinWidth;
	max-width: $sidrMaxWidth;
	overflow-x: hidden;
	overflow-y: auto;
	background: $c-countryMeadow;
	//border-right: 1px solid #fff;
	color: #fff;
	box-shadow: 0 0 4px 4px rgba(#000, .3);
	//@include opacity(0);
	//transition: opacity $transitionTime linear;

	.sidr-inner {
		padding: 15px;
	}

	&.right {
		left: auto;
		right: -$sidrWidth;
	}

	&.left {
		left: -$sidrWidth;
		right: auto;
	}
}

.sidr-open {
	overflow: hidden;

	/*.sidr {
		transition: opacity $transitionTime linear;
		@include opacity(1);
	}*/
}

.mobileNavHeader {
	@extend .clearfix;
	@extend %padding-smart;
	padding-top: $paddingDefault * .7;
	padding-bottom: $paddingDefault * .6;
	text-align: center;
	background-color: $c-nonchalant-white;
	color: $c-trout;
	width: 100%;

	a {
		text-decoration: none;
		display: block;
		transition: color $transitionTime $transitionFunction;

		&:hover {
			color: $c-troutHover;
		}
	}

	.icon-memorial-green {
		@include fontsize($fs-mobileLogo);
	}

}

.mobileNavBody {
	padding: $paddingDefault;
	text-align: center;

	ul {
		margin: 0 0 $marginDefault 0;
		list-style-type: none;

		ul {
			margin: 0 0 0 $marginDefault;
		}
	}

	li {
		text-align: left;
		display: block;
		//margin-bottom: $marginDefault;
	}

	a {
		display: block;
		text-decoration: none;
		font-family: $ff-serif;
		padding: rems(10px);


		&.selected, &:hover {
			color: $c-nonchalant-white;
		}
	}
}