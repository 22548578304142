.card {
	@extend %centerBackground;

	display: table;
	table-layout: fixed;
	width: 100%;
	height: 100%;
	overflow: hidden;
	font-weight: 300;
}

.card--image {
	height: 300px;
	min-height: 100%;
}


.card--logo {
	background-size: contain;
	background-color: $c-desertStorm;
	position: relative;


	@include query_min($smallBreakpoint){
		background-size: auto;
	}
}
.card--logo, .card--logoWrapper, .card--map {
	height: 200px;

	@include query_min($smallBreakpoint){
		height: 300px;
	}
	@include query_min($sidrBreakpoint){
		height: 400px;
	}
}

.card--logo, .card--logoWrapper {
	@include query_max($sidrBreakpoint - 1) {
		&.card--collapsible {
			height: 0;
		}
	}
}

.card--logoInner {
	@extend %centerBackground;
	background-size: contain;
	width: 50%;
	height: 40%;
	position: absolute;
	top: 30%;
	left: 25%;
}

.card--map {
	position: relative;
	.oh-google-map-canvas {
		display: block;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}

	img {
		max-width: none;
		width: auto;
	}
}

.card--content:not(.card--form) {
	.cardContent {
		@extend %lobotomize;
		@extend %defaultText;

		@include query_min($smallBreakpoint) {
			padding: $paddingDefault*1.5 $paddingDefault*1.5;
		}

		@include query_min($sidrBreakpoint) {
			padding: $paddingDefault*2 $paddingDefault*4;
		}
	}
}

.card--content.card--form {
	.cardContent {
		@include query_min($smallBreakpoint) {
			padding: $paddingDefault*1.5 $paddingDefault*1.5;
		}

		@include query_min(1100px) {
			padding: $paddingDefault*2 $paddingDefault*4;
		}
	}
}

.cardContent {
	position: relative;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	display: table-cell;
	width: 100%;
	height: 100%;
	vertical-align: middle;
	overflow: hidden;
	padding: $paddingDefault;

	.buttonHolder {
		margin-top: $marginDefault*1.5;
	}
}

.cardContent--buttonDock {
	padding-bottom: $paddingDefault * 7;
}

.cardType {
	text-align: center;
	font-family: $ff-serif;
	@include fontsize($fs-cardType);
	line-height: $lh-cardType;
	margin-bottom: $marginDefault*1.5;
}

.cardHeading {
	font-family: $ff-serif;
	@include fontsize($fs-cardHeading);
	line-height: $lh-cardHeading;
	text-align: center;
}

.cardCaption {
	@include fontsize($fs-cardCaption);
	line-height: $lh-cardCaption;
	text-align: center;
}

.card--darkBg {
	&, .cardContent {
		color: #fff;
	}
}
.card--topAlign {
	&, .cardContent {
		vertical-align: top;
	}
}

//---------- Card Carousel ----------
.cardCarousel {
	width: 100%;
	height: 100%;
	margin: 0;
	position: absolute;
	top: 0;
	left: 0;
	background-color: $c-riverBed;
	height: 200px;
	@include query_min($smallBreakpoint){
		height: 300px;
	}
	@include query_min($sidrBreakpoint){
		height: 400px;
	}
}

.cardCarouselSlide {
	width: 100%;
	height: 100%;
	margin: 0;
	@extend %centerBackground;
	overflow: hidden;
	background-color: #fff;
}

.slick-track, .slick-list {
	 height: 100.1%;
}

.cardCarouselBg {
	width: 100%;
	height: 100%;
	margin: 0;
	position: absolute;
	top: 0;
	left: 0;
	@extend %centerBackground;
	@include opacity(.9);
}

//---------- .cardEventInfo ----------
.cardEventInfo, .cardNewsInfo {
	text-align: center;

	.cardContent {
		* + * {
			margin-top: rems($marginDefault*.2);

		}
	}
}