//---------- Colors ----------
$c-riverBed: #485062; //main slider overlay (dark), switcher block
$c-shuttleGray: #606578;
$c-shuttleGrayLight: #636466;
$c-frenchGray: #bfc2cb; //events & contact in menu
$c-osloGray: #838a90; //main slider overlay (light)
$c-slateGray: #7A8895; //commercial map active color
$c-gray1: #7f7f7f; //card social icons
$c-chicago: #5a5955;
$c-aluminum: #a7a9ac; //social icons bg
$c-trout: #484b59;
$c-troutHover: darken(#484b59, 10%);

//bg colors
$c-sage: #9ca285;
$c-cottonSeed: #bfbcb4;
$c-nonchalant-white: #deddd2; //background, header top
$c-countryMeadow: #1a614c;

$c-blackHaze: #edeeee;
$c-blackHaze2: #ebecec; //commercial map bg
$c-desertStorm: #f4f4f4; //switcher block
$c-cararra: #f0f1ed; //switcher block
$c-pampas: #f6f5f3;
$c-athensGray: #e4e5e8;

$c-seaShell: #f1f1f1;
$c-gallery: #ebebeb;
$c-gray2: #e8e8e8;

// grid backgrounds
$c-mistGray: #cac9bf;
$c-celeste: #d2d1c6;


//lightbox button color
$c-naturalGray : #93928c;

//social hover colors
$c-facebookBlue: #537bbd;
$c-twitterBlue: #78cdf0;
$c-instagramBlue: #517fa4;
$c-pinterestRed: #bd081c;

//text color
$c-contentText: $c-riverBed;

//slick dot colors
$c-silverSand: #c4c6c9;
$c-slickDot: $c-silverSand;
$c-slickDotHover: $c-osloGray;

//forms
$c-error: red;
$c-placeholder: lighten($c-contentText,20%);

//---------- Background Colors ----------
//generate background color classes
$colors: (
	RiverBed : $c-riverBed,
	ShuttleGray : $c-shuttleGray,
	FrenchGray : $c-frenchGray,
	OsloGray : $c-osloGray,
	Sage : $c-sage,
	CottonSeed : $c-cottonSeed,
	BlackHaze : $c-blackHaze,
	DesertStorm : $c-desertStorm,
	Cararra : $c-cararra,
	Pampas : $c-pampas,
	AthensGray : $c-athensGray,
	Gray2 : $c-gray2,
	SeaShell : $c-seaShell,
	Gallery : $c-gallery,
	CountryMeadow : $c-countryMeadow,
	NonchalantWhite : $c-nonchalant-white,
	MistGray : $c-mistGray,
	Celeste : $c-celeste,
);
@each $name, $color in $colors {
	.bg-#{$name} {
		background-color: $color;
	}

	.bg-#{$name}--faded {
		background-color: $color;
		background-color: rgba($color, .9);
	}
}

//---------- Spacing ----------
$marginDefaultPx: 20px;
$marginDefault: rems($marginDefaultPx);
$paddingDefaultPx: 20px;
$paddingDefault: rems($paddingDefaultPx);
$gutterFormFields: $paddingDefault * .15;

//---------- Timing ----------
$transitionTime: .5s;
$transitionFunction: ease;

//---------- Typography ----------
$ff-sans: 'franklin-gothic-atf', sans-serif;
$ff-sansBold: 700;

$ff-serif: 'jaf-lapture-display', serif;
//note: Lora is currently not loading the bold font
//$ff-serifBold: 700;

//font sizes
$fs-default: 16px;
$lh-default: 1.56;
$fs-subMenu: 16px;

//logo icon
$fs-logo: 68px;
$fs-mobileLogo: 62px;
//mobile menu toggle icon
$fs-mobileToggle: 48px;

//full slider
$fs-fullSliderHeading: 48px;
$lh-fullSliderHeading: 1.14;
$fs-fullSliderCaption: 22px;
$lh-fullSliderCaption: 1.59;
$fs-fullSliderPlayIcon: 86px;

//buttons
$fs-buttonsLarge: 18px;
$fs-buttons: 16px;
$fs-buttonsSmall: 14px;
$fs-socialIcons: 18px;
$fs-socialIconsDetail: 24px;
$fs-buttonSubmit: 20px;

//cards
$fs-cardType: 24px;
$lh-cardType: 1.2;
$fs-cardHeading: 22px;
$lh-cardHeading: 1.36;
$fs-cardCaption: $fs-default;
$lh-cardCaption: $lh-default;

//headings
$fs-h1: 24px;
$fs-h2: 22px;
$fs-h3: 20px;
$fs-h4: 18px;
$fs-h5: 17px;
$fs-h6: 16px;

//rich text callout
$fs-richTextCallout: 18px;
$lh-richTextCallout: 1.78;

//disclaimer
$fs-disclaimer: 13px;

//---------- Breakpoints ----------
$sidrBreakpoint: 768px;
$standardWidth: 1251px;
$fenceWidth: 1920px;

$smallBreakpoint: $sidrBreakpoint*.5;

$formBreakpoint: rems(546px);
$formTextWidth: rems(546px);
$threeColumnTextBreakpoint: 1000px;
$svgMapMaxWidth: 1000px;

//---------- MG Icon ----------
$ff-icons: 'mg';
$ff-hash: 'eq8l7z';
$icon-pinterest: "\e900";
$icon-eoh: "\e60a";
$icon-triangle-down: "\e60b";
$icon-memorial-green: "\e600";
$icon-midway: "\e601";
$icon-instagram: "\e602";
$icon-facebook: "\e603";
$icon-twitter: "\e604";
$icon-close: "\e605";
$icon-next: "\e606";
$icon-previous: "\e607";
$icon-play: "\e608";
$icon-circle-solid: "\e609";