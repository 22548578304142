.pagination {
	padding: $paddingDefault;
	text-align: center;
	border-top: 1px solid $c-cottonSeed;

	a, span {
		display: inline-block;
		margin: 0 $marginDefault*.5;
		color: $c-frenchGray;
	}

	a {
		text-decoration: none;
		color: $c-shuttleGray;
	}
}