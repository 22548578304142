@charset "UTF-8";

// Default Variables
$slick-font-family: $ff-icons;
$slick-loader-path: "./" !default;
$slick-arrow-color: white !default;
$slick-dot-color: $c-slickDot;
$slick-dot-color-active: $c-slickDotHover;
$slick-prev-character: $icon-previous;
$slick-next-character: $icon-next;
$slick-dot-character: $icon-circle-solid;
$slick-dot-size: 8px !default;
$slick-opacity-default: 0.75 !default;
$slick-opacity-on-hover: 1 !default;
$slick-opacity-not-active: 0.25 !default;

$slick-offset: $marginDefault;

@function slick-image-url($url) {
    @return url($slick-loader-path + $url);
}

/* Slider */

.slick-list {
    .slick-loading & {
        background: #fff url("/assets/images/ajax-loader.gif") center center no-repeat;
    }
}

/* Arrows */

.slick-prev,
.slick-next {
    position: absolute;
    display: none;
    height: 32px;
    width: 34px;
    line-height: 0;
    font-size: 0;
    cursor: pointer;
    background: transparent;
    color: transparent;
    top: 50%;
    margin-top: -10px;
    padding: 0;
    border: none;
    outline: none;
    z-index: 1000;
    text-align: center;
    &:hover, &:focus {
        outline: none;
        background: transparent;
        color: transparent;
        &:before {
            opacity: $slick-opacity-on-hover;
            transition: opacity $transitionTime linear;
        }
    }
    &.slick-disabled:before {
        opacity: $slick-opacity-not-active;
    }

    @include opacity(0);
    transition: opacity $transitionTime linear;

    @include query_min($sidrBreakpoint) {
        display: block;
        .slick-slider:hover &, .slick-group:hover & {
            @include opacity(1);
        }
    }
}

.slick-prev:before, .slick-next:before {
    font-family: $slick-font-family;
    @include fontsize(32px);
    line-height: 1;
    color: $slick-arrow-color;
    opacity: $slick-opacity-default;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    width: 100%;
    text-align: center;
    display: block;
}

.slick-prev {
    left: $slick-offset;
    [dir="rtl"] & {
        left: auto;
        right: $slick-offset;
    }
    &:before {
        content: $slick-prev-character;
        [dir="rtl"] & {
            content: $slick-next-character;
        }
    }
}

.slick-next {
    right: $slick-offset;
    [dir="rtl"] & {
        left: $slick-offset;
        right: auto;
    }
    &:before {
        content: $slick-next-character;
        [dir="rtl"] & {
            content: $slick-prev-character;
        }
    }
}

/* Dots */
.slick-dots {
    position: absolute;
    bottom: - $marginDefault*1.6;
    list-style: none;
    display: block;
    text-align: center;
    padding: 0;
    width: 100%;
    li {
        position: relative;
        display: inline-block;
        height: 20px;
        width: 20px;
        margin: 0;
        padding: 0;
        cursor: pointer;
        button {
            border: 0;
            background: transparent;
            display: block;
            height: 20px;
            width: 20px;
            outline: none;
            line-height: 0;
            font-size: 0;
            color: transparent;
            padding: 4px;
            cursor: pointer;
            &:hover, &:focus {
                outline: none;
            }
            &:before {
                position: absolute;
                top: 0;
                left: 0;
                content: $slick-dot-character;
                width: 20px;
                height: 20px;
                font-family: $slick-font-family;
                font-size: $slick-dot-size;
                line-height: 20px;
                text-align: center;
                color: $slick-dot-color;
                transition: color $transitionTime linear;
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: grayscale;
            }
        }
        &.slick-active button:before {
            color: $slick-dot-color-active;
        }
    }
}