.button, a.button {
	display: inline-block;
	padding: $paddingDefault/2 $paddingDefault;
	border: 1px solid currentColor;
	text-decoration: none;
	text-align: center;
	text-transform: uppercase;
	@include fontsize($fs-buttons);
	line-height: 1;
	min-width: 10em;
	outline: 0;
	max-width: 100%;
	transition: backgound-color $transitionTime linear;

	&:hover {
		background-color: rgba(#fff, .1);
	}

	@include query_min($smallBreakpoint) {
		padding: $paddingDefault/2 $paddingDefault*2;
	}
	
	.buttonGroup &:hover {
		background-color: rgba($c-contentText, .05);
	}

	/*&.button--solid {
		background-color: $c-riverBed;
		border-color: $c-riverBed;
	}*/

	&.button--docked {
		width: 100%;
		display: block;
		background-color: $c-chicago;
		padding: $paddingDefault * 1.4 $paddingDefault !important;
		border: none;

		&:hover {
			background-color: $c-chicago;
		}
	}
}

/*.button--large, a.button--large {
	border-width: 2px;
	@include fontsize($fs-buttonsLarge);
}*/

.button--small, a.button--small {
	@include fontsize($fs-buttonsSmall);
	border-width: 1px;
	padding: $marginDefault*.3  $marginDefault*1.25;
	width: auto;
	min-width: 0;
}

.button--content {
	display: inline-block;
	@extend .button;
	transition: color $transitionTime linear, background-color $transitionTime linear;

	&:hover {
		background-color: $c-contentText;
		color: #fff;
	}
}

.buttonHolder {
	@extend .clearfix;
	display: block;
	text-align: center;
}

.buttonHolder--docked {
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
}

.submitButton {
	border: none;
	background-color: $c-riverBed;
	color: #fff;
	@include fontsize($fs-buttonSubmit);
	padding: $paddingDefault*.65 $paddingDefault*3;

	@include query_min( $sidrBreakpoint ) {
		padding-left: $paddingDefault * 6;
		padding-right: $paddingDefault * 6;
	}
}

.lightboxButton {
	background-color: $c-naturalGray;
	color: #fff;
	padding: $paddingDefault*.75 $paddingDefault*2;
	text-transform: uppercase;
	@include fontsize($fs-default);
	text-decoration: none;
}