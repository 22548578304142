//---------- clearfix ----------
.clearfix {
	&:before,
	&:after {
		content: "";
		display: table;
	}
	&:after {
		clear: both;
	}

	zoom: 1; /* For IE 6/7 (trigger hasLayout) */
}

//---------- text ----------
%hideText {
	text-indent: 100%;
	white-space: nowrap;
	overflow: hidden;
}

%antialiased {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

%defaultText {
	font-size: $fs-default;
	line-height: $lh-default;
	color: $c-shuttleGrayLight;
}
/*%breakWord {
	-ms-word-break: break-all;
	word-break: break-all;

	/!* Non standard for webkit *!/
	word-break: break-word;

	-webkit-hyphens: auto;
	-moz-hyphens: auto;
	-ms-hyphens: auto;
	hyphens: auto;
}*/

%noSelect {
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

//---------- images ----------
.img-stretch {
	width: 100%;
}

%centerBackground {
	background-repeat: no-repeat;
	background-position: center center;
	background-size: cover;
}

//---------- media object ----------
//media block
.mb {
	overflow: hidden;
	_overflow: visible;
	zoom: 1;
}
//media body (content)
.mb-body {
	margin-top: 0;
	overflow: hidden;
	_overflow: visible;
	zoom: 1;
}
//media media (image)
.mb .mb-media, %mb-media {
	float: left;
	margin-right: $marginDefault;
	margin-top: 0;
}
.mb .mb-media img {
	display: block;
}
.mb .mb-media--alt, %mb-media--alt {
	float: right;
	margin-left: $marginDefault;
	margin-top: 0;
}

//---------- hidden ----------
.hidden {
	display: none !important;
	visibility: hidden;
}

.visuallyHidden {
	border: 0;
	clip: rect(0 0 0 0);
	height: 1px;
	margin: -1px;
	overflow: hidden;
	padding: 0;
	position: absolute;
	width: 1px;
}

.invisible {
	visibility: hidden;
}

//---------- padding/margins ----------
.padded-content {
	padding: $paddingDefault;
}

%lobotomize {
	* + * {
		margin-top: rems($marginDefault);

	}
}

//---------- play icon ----------
.playIcon {
	@extend .icon-circle-solid;
	color: rgba(#fff,.2);
	position: relative;
	display: inline-block;
	transition: color $transitionTime linear;
	vertical-align: middle;

	&:hover {
		color: rgba(#fff,.3);
	}

	span {
		@extend .icon-play;
		color: #fff;
		position: absolute;
		font-size: 50%;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		text-align: center;
		padding-top: 25%;
		padding-left: 10%;
	}
}

//---------- super text ----------
.sup {
	font-size: 70%;
	display: inline-block;
	vertical-align: top;
}

//---------- zoom in cursor ----------
.zoom-in {
	cursor: -webkit-zoom-in; cursor: -moz-zoom-in;
}

//---------- span dividers ----------
.spanDividers {
	& > span {
		margin-top: 0;
		display: inline-block;
		padding: 0 $paddingDefault*.5 0 0;
		&:after {
			content: '|';
			margin-left: $paddingDefault*.5;
		}
		&:last-of-type {
			padding: 0;
			&:after {
				display: none;
			}
		}
	}
}
