.footer {
	@extend .standard-width;
	padding: $paddingDefault * .8;

	a {
		text-decoration: none;
		display: inline-block;
		vertical-align: middle;
		transition: color $transitionTime linear;
		text-transform: uppercase;

		&:hover {
			color: $c-troutHover;
		}
	}

	.footerLinks {
		text-align: center;
		width: 100%;
		display: block;
	}

	.corporate {
		text-align: center;
		width: 100%;
		display: block;
		margin-top: $marginDefault*.75;

		a {
			@include fontsize(14px);
		}
	}

	.socialLink + .footerLink {
		margin-left: $marginDefault * .5;
	}

	.footerLink {
		@include fontsize(18px);
	}

	@include query_min($sidrBreakpoint) {
		.footerLinks {
			float: left;
			width: 40%;
			padding-right: $marginDefault;
			text-align: left;
		}

		.corporate {
			float: right;
			width: 50%;
			padding-left: $marginDefault;
			text-align: right;
			margin-top: 0;
		}
	}

}

