.threeColumnImages {
	@extend .clearfix;
	font-size: 0;
	text-align: center;
	display: none;

	@include query_min($sidrBreakpoint) {
		display: block;
		overflow: hidden;
	}
}

.threeColumnImages-column {
	display: inline-block;
	vertical-align: top;
	text-align: center;
	width: 33.33%;
	margin: 0;
	//flex: 1;

	.slide {
		display: none;

		&:first-of-type {
			display: block;
		}

		&.slick-slide {
			display: block;
		}
	}

	.slideImgBg {
		@extend %centerBackground;
		height: 250px;
	}
}