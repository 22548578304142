.mobile-menu-toggle,
.mobile-menu-close {
	@extend .button;
	@extend .button--small;
	margin-top: $marginDefault * .5;
	outline: none;
	display: inline-block;
	text-align: center;
}

.header-top {
	text-align: center;

	margin-bottom: $paddingDefault * .8;

	@include query_min($sidrBreakpoint) {
		margin-bottom: 0;
		text-align: left;
	}

	.standard-width {
		@extend %padding-smart;
		padding-top: $paddingDefault * .7;
		padding-bottom: $paddingDefault * .6;

		@include query_min($sidrBreakpoint) {
			padding-top: $paddingDefault * 1.1;
			padding-bottom: $paddingDefault * .8;
		}
	}

	a, button {
		text-decoration: none;
		line-height: 1;
		transition: color $transitionTime $transitionFunction;

		&:hover {
			color: $c-troutHover;
		}
	}

	a {
		display: block;
	}

	.icon-memorial-green {
		@include fontsize($fs-mobileLogo);

		@include query_min($sidrBreakpoint) {
			@include fontsize($fs-logo);
			float: left;
		}
	}

	.mobile-menu-toggle {
		@include query_min($sidrBreakpoint) {
			display: none !important;
		}
	}
}

.sidr-open {
	.mobile-menu-toggle {
		visibility: hidden;
	}
}

.header-bottom {
	display: none;

	nav {
		@extend .standard-width;
		@extend %padding-smart;
		display: block;
		padding-top: $marginDefault;
		padding-bottom: $marginDefault;
		text-align: right;
	}

	@include query_min($sidrBreakpoint) {
		display: block;
	}
}

.main-menu {
	li {
		display: inline-block;
		border-right: 1px solid #fff;
		&:last-of-type {
			border-right: none;
			a {
				padding-right: 0;
			}
		}
	}

	a {
		text-decoration: none;
		text-transform: uppercase;
		font-family: $ff-serif;
		padding: 0 rems(12);
		transition: color $transitionTime $transitionFunction;

		&.selected,
		&:hover {
			color: $c-nonchalant-white;
		}
	}
}