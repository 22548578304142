.full-width {
	width: 100%;
	margin: 0 auto;
}

.fence-width {
	width: 100%;
	max-width: $fenceWidth;
	margin-left: auto;
	margin-right: auto;
}

.standard-width {
	@extend .clearfix;
	width: $standardWidth;
	max-width: 100%;
	margin-left: auto;
	margin-right: auto;
}

%padding-smart {
	padding-left: $marginDefault;
	padding-right: $marginDefault;

	@include query_min( $standardWidth + $marginDefaultPx * 2 ) {
		padding-left: 0;
		padding-right: 0;
	}
}

.floatLeft {
	float: left;
	width: 50%;
	padding-right: $marginDefault;
}

.floatRight {
	float: right;
	width: 50%;
	padding-left: $marginDefault;
}

@include query_min($sidrBreakpoint) {
	.floatLeft-smart {
		float: left;
		width: 50%;
		padding-right: $marginDefault;
	}

	.floatRight-smart {
		float: right;
		width: 50%;
		padding-left: $marginDefault;
	}
}
