// ------- TYPOGRAPHIC STYLES
body {
	@include fontsize($fs-default);
	font-family: $ff-sans;
}

a {
	color: currentColor;

	&:hover {
		text-decoration: none;
	}
}

em {
	font-style: italic;
}

strong {
	font-weight: bold;
}

h1, .fauxH1,
h2, .fauxH2,
h3, .fauxH3,
h4, .fauxH4,
h5, .fauxH5,
h6, .fauxH6 {
	font-family: $ff-serif;
}

h1, .fauxH1 {
	@include fontsize($fs-h1);
}
h2, .fauxH2 {
	@include fontsize($fs-h2);
}
h3, .fauxH3 {
	@include fontsize($fs-h3);
}
h4, .fauxH4 {
	@include fontsize($fs-h4);
}
h5, .fauxH5 {
	@include fontsize($fs-h5);
}
h6, .fauxH6 {
	@include fontsize($fs-h6);
}