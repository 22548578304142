.subFooter {
	border-top: 1px solid $c-cottonSeed;
	padding: $paddingDefault * .8;
}

.disclaimer {
	text-align: center;
	@include fontsize($fs-disclaimer);
	color: $c-shuttleGrayLight;

	.icon-eoh {
		margin-right: $marginDefault * .2;
		display: inline-block;
	}
}