.panelContainer {
	@extend .clearfix;
	display: block;
	width: 100.24%; //the .1 corrects a 1px deficiency in chrome
	height: 100%;
	position: relative;
	margin-left: -0.1%;

	@include query_min($sidrBreakpoint) {
		display: table;
	}
}

.panel {
	margin: 0;
	display: block;

	@include query_min($sidrBreakpoint){
		display: table-cell;
		table-layout: fixed;
		vertical-align: middle;
	}
}

.panel--withBgImage {
	@extend %centerBackground;
}

.panel--oneThird {
	width: 100%;

	@include query_min($sidrBreakpoint){
		width: 50%;
	}
	@include query_min($standardWidth){
		width: 33.333%;
	}

}
.panel--twoThirds {
	width: 100%;

	@include query_min($sidrBreakpoint){
		width: 50%;
	}
	@include query_min($standardWidth){
		width: 66.666%;
	}
}
.panel--half {
	width: 100%;

	@include query_min($sidrBreakpoint){
		width: 50%;
	}
}