.threeColumnText {
	@extend .clearfix;
	font-size: 0;
	text-align: center;

	@include query_min($threeColumnTextBreakpoint) {
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		overflow: hidden;
	}
}

.threeColumnText-column {
	display: inline-block;
	vertical-align: top;
	text-align: center;
	@extend %lobotomize;
	@include fontsize($fs-default);
	line-height: $lh-default;
	padding: $paddingDefault;

	@include query_min($threeColumnTextBreakpoint) {
		width: 33.33%;
		display: inline-block;
		margin: 0;
		padding: $paddingDefault*2 $paddingDefault;

		flex: 1;
	}
}