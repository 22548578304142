.detailInfo {
	margin: 0 auto;
	text-align: center;
	padding-top: $paddingDefault;

	.button, a.button {
		/*display: block;
		padding-left: $paddingDefault*.1;
		padding-right: $paddingDefault*.1;*/

		margin-right: $marginDefault * .5;
	}

	.socialGroup {
		color: $c-gray1;
	}


}

.hours {
	font-family: $ff-serif;
	font-size: $fs-richTextCallout;
	line-height: $lh-cardHeading;
}

.hoursHeading {
	font-style: italic;
}

.hoursHeading + .hourTimes {
	margin-top: 0;
}