.switcherTabs {
	flex-direction: row;
	flex-wrap: nowrap;
	flex-grow: 1;
	flex-shrink: 1;
	flex-basis: auto;
	align-items: center;
	display: none;

	&--ready {
		display: flex;
	}
}

.switcherTab {
	display: flex;
	flex: 1 1 auto;
	max-width: 100%;
	flex-direction: column;
	flex-wrap: nowrap;

	button {
		display: flex;
		padding: $paddingDefault*4 $paddingDefault*.5;
		min-height: rems(250px);
		align-items: center;
		justify-content: center;

		span {
			display: inline-block;
			position: relative;
		}
	}

	&.switcherTab--selected {
		span:after {
			content: '';
			position: absolute;
			width: 19px;
			height: 13px;
			background: transparent url(/assets/svg/down-arrow-dark.svg) no-repeat center center;
			bottom: -40px;
			z-index: 1;
			left: 50%;
			transform: translateX(-50%);
		}

		&.bg--dark {
			span:after {
				background-image: url(/assets/svg/down-arrow-light.svg);
			}
		}
	}

	span {
		@include query_max(512px) {
			@include fontsize(12px);
			min-height: 0;
		}
	}

	@include query_max(512px) {
		button {
			min-height: 175px;
			padding: $paddingDefault $paddingDefault*.5;
		}

		span {
			@include fontsize(16px);
		}
	}
}

.switcherTabBlock--inactive {
	display: none;
}