form {
	@extend .clearfix;

	.buttonHolder {
		margin-top: $marginDefault*1.25;
	}
}

::-webkit-input-placeholder {
	color: $c-placeholder;
}
:-moz-placeholder {
	color: $c-placeholder;
}
::-moz-placeholder {
	color: $c-placeholder;
}
:-ms-input-placeholder {
	color: $c-placeholder;
}

label {
	display: block;
	margin-bottom: $marginDefault*.25;

	&.required:after {
		content: '*';
		display: inline-block;
		font-weight: bold;
		padding-left: 1px;
	}
}

input[type=text],
input[type=email],
textarea,
select,
option {
	display: block;
	width: 100%;
	padding: $paddingDefault * .5;
	text-align: center;
}

input[type=text],
input[type=email],
textarea,
select {
	border: 2px solid $c-frenchGray;

	&:focus {
		outline: none;
		box-shadow: 0 0 3px $c-frenchGray;
		border-color: darken($c-frenchGray,10%);
	}
}




textarea {
	//min-height: 250px;
}

input[type=submit] {
	@extend .submitButton;
}


.fieldGroup {
	@extend .clearfix;
}

.fieldGroup--half {
	.field {
		@include query_min($formBreakpoint) {
			width: 50%;
			float: left;

			&:nth-child(1) {
				padding-right: $gutterFormFields;
			}

			&:nth-child(2) {
				padding-left: $gutterFormFields;
			}
		}
	}
}

.fieldGroup--third {
	.field {
		@include query_min($formBreakpoint) {
			width: 33%;
			float: left;

			&:nth-child(1) {
				padding-right: $gutterFormFields;
			}

			&:nth-child(2) {
				padding-left: $gutterFormFields;
				padding-right: $gutterFormFields;
			}

			&:nth-child(3) {
				padding-left: $gutterFormFields;
			}
		}
	}

}

.fieldGroup + .fieldGroup {
	@include query_min($formBreakpoint) {
		margin-top: $marginDefault *.75;
	}
}

.field {
	margin-top: $marginDefault*.75;

	@include query_min($formBreakpoint) {
		margin-top: 0;
	}
}

.errors {
	list-style-type: none;
	//margin: 2px 0 0;
	padding: 0;

	li {
		color: $c-error;
		margin-top: 0;
		//margin-bottom: 5px;
	}
}

.checkboxLabel {
	font-family: $ff-serif;
	font-size: $fs-richTextCallout;
	text-align: center;

	input {
		margin-right: $marginDefault * .25;
	}
}