.commercialMap {

	&-interactive {
		display: none;
	}

	@include query_min(800px) {
		&-interactive {
			display: block;
		}

		&-mobile {
			display: none;
		}
	}

	&-filters {
		text-align: center;
		padding: $paddingDefault*2;

		p {
			margin-bottom: $marginDefault;
		}
	}

	&-filter {
		display: inline-block;

		& + & {
			margin-left: $marginDefault;
		}

		select {
			display: inline-block;
			width: 267px;
		}
	}

	&-inner {
		padding: $paddingDefault*2;
		background-color: $c-blackHaze2;
	}

	&-container {
		position: relative;

		svg {
			position: relative;
			z-index: 1;

			[id^=text-] {
				pointer-events: none;

				path {
					pointer-events: none;
				}
			}
		}
	}

	&-popover {
		position: absolute;
		top: 0;
		left: 0;
		transform: translate(-50%, -120%);
		width: 158px;
		height: 124px;
		box-shadow: 0 6px 8px 0 rgba(0, 0, 0, 0.3);
		display: none;

		&.popover--open {
			display: block;
		}

		&.popover--hover {
			z-index: 300 !important;
		}

		&Inner {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			border: 4px solid #fff;
			background-size: contain;
			background-repeat: no-repeat;
			background-position: center center;
			background-origin: content-box;
			padding: 12% 10%;
			z-index: 2;
			background-color: $c-gray2;
		}

		&:after {
			content: '▼';
			display: block;
			//width: 0;
			//height: 0;
			//border-style: solid;
			//border-width: 22px 12.5px 0 12.5px;
			//border-color: #fff transparent transparent transparent;
			color: #fff;
			position: absolute;
			top: 100%;
			left: 50%;
			transform: translate(-50%, -6px);
			z-index: 1;
			text-shadow: 0 6px 8px rgba(0, 0, 0, 0.3);
			@include fontsize(25px);
			line-height: 1;
		}
	}

	//locations
	.loc--enabled {
		fill: $c-slateGray !important;
		cursor: pointer;
	}

	//chosen overrides
	&-filter {

		.chosen-container-single .chosen-single {
			padding: $paddingDefault * .5 0;
			border: 2px solid $c-frenchGray;
			background: white;
			border-radius: 0;
			box-shadow: none;
			height: 45px;
			color: $c-riverBed;
			font-family: $ff-sans;
			text-transform: uppercase;
			font-size: 16px;
		}

		.chosen-container-active.chosen-with-drop .chosen-single {
			background: white;
			border: 2px solid $c-frenchGray;
		}

		.chosen-container-single .chosen-drop {
			border-radius: 0;
			margin-top: 0;
			padding: $paddingDefault * .5 0;
		}

		.chosen-container .chosen-results {
			color: $c-riverBed;
			font-family: $ff-sans;
			text-transform: uppercase;
			font-size: 14px;

			li.highlighted{
				background-color: $c-shuttleGray;
				background-image: none;
				color:#fff;
			}
		}

		.chosen-container-single .chosen-single abbr {
			top: 50%;
			transform: translateY(-50%);
		}

		.chosen-container-single .chosen-single div b {
			background-position: 4px 11px;
		}
		.chosen-container-active.chosen-with-drop .chosen-single div b{
			background-position: -14px 11px;
		}
	}

	&-mobile {
		padding: $paddingDefault*1.5;
		background-color: $c-blackHaze2;

		a {
			position: relative;
			display: block;
			background-color: $c-shuttleGray;
		}

		img {
			opacity: 0.3;
			position: relative;
			z-index: 1;
			width: 100%;
			object-fit: cover;
		}

		span {
			text-decoration: none;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			z-index: 2;
			color: #fff;
		}
	}
}