@font-face {
	font-family: $ff-icons;
	src:url('/assets/fonts/mg.eot?#{$ff-hash}');
	src:url('/assets/fonts/mg.eot?#iefix#{$ff-hash}') format('embedded-opentype'),
	url('/assets/fonts/mg.ttf?#{$ff-hash}') format('truetype'),
	url('/assets/fonts/mg.woff?#{$ff-hash}') format('woff'),
	url('/assets/fonts/mg.svg?#{$ff-hash}#mg') format('svg');
	font-weight: normal;
	font-style: normal;
	font-display: block;
}

%mg-font {
	font-family: $ff-icons;
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;

	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.icon-pinterest:before {
	@extend %mg-font;
	content: $icon-pinterest;
}
.icon-eoh:before {
	@extend %mg-font;
	content: $icon-eoh;
}
.icon-triangle-down:before {
	@extend %mg-font;
	content: $icon-triangle-down;
}
.icon-memorial-green:before {
	@extend %mg-font;
	content: $icon-memorial-green;
}
.icon-midway:before {
	@extend %mg-font;
	content: $icon-midway;
}
.icon-instagram:before {
	@extend %mg-font;
	content: $icon-instagram;
}
.icon-facebook:before {
	@extend %mg-font;
	content: $icon-facebook;
}
.icon-twitter:before {
	@extend %mg-font;
	content: $icon-twitter;
}
.icon-close:before {
	@extend %mg-font;
	content: $icon-close;
}
.icon-next:before {
	@extend %mg-font;
	content: $icon-next;
}
.icon-previous:before {
	@extend %mg-font;
	content: $icon-previous;
}
.icon-play:before {
	@extend %mg-font;
	content: $icon-play;
}
.icon-circle-solid:before {
	@extend %mg-font;
	content: $icon-circle-solid;
}