.featuresSlider {
	margin: $marginDefault*2 0;
	width: 100.05%;

	.slide {
		.no-js & {
			display: none;
		}

		&:nth-child(1) {
			display: block;
		}
	}

	.card--image {
		@include query_min($sidrBreakpoint)
		{
			height: 100%;
			min-height: 200px;
		}
	}
}

