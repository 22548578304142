.subHeader {
	@extend .clearfix;
	padding: $paddingDefault*.75 $paddingDefault*1.75 0;

	h1 {
		margin: 0 0 $paddingDefault*.75 0;
		text-align: center;

		@include query_min( $sidrBreakpoint ) {
			text-align: left;
			float: left;
		}

		a {
			text-decoration: none;
		}
	}

	& > ul {
		float: right;
		@include fontsize($fs-subMenu);
		margin: rems(4px) 0 $paddingDefault*.75 0;
		display: none;

		@include query_min($sidrBreakpoint) {
			display: block;
		}

		li {
			display: inline-block;
			margin-left: $marginDefault;
			white-space: nowrap;
		}

		a {
			text-transform: uppercase;
			text-decoration: none;
		}
	}
}