.socialGroup a,
a.socialLink {
	text-decoration: none;
	padding-right: $paddingDefault*.5;
	@include fontsize($fs-socialIcons);
	transition: color $transitionTime linear;
}

.socialGroup a {
	@include fontsize($fs-socialIconsDetail);
}

.icon-twitter.socialHover:hover {
	color: $c-twitterBlue;
}

.icon-instagram.socialHover:hover {
	color: $c-instagramBlue;
}

.icon-facebook.socialHover:hover {
	color: $c-facebookBlue;
}

.icon-pinterest.socialHover:hover {
	color: $c-pinterestRed;
}

.socialIcons {
	text-align: center;
	padding-top: $paddingDefault*2;
	padding-bottom: $paddingDefault*2;

	a {
		display: inline-block;
		width: 36px;
		height: 36px;
		border-radius: 50%;
		position: relative;
		background-color: $c-aluminum;
		color: #fff;
		transition: background-color $transitionTime;
	}

	a + a {
		margin-left: $marginDefault;
	}

	span {
		@include fontsize(18px);
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}

	.icon-twitter {
		@include fontsize(14.6px);
	}
}

.socialIcon {
	&--instagram:hover {
		background-color: $c-instagramBlue;
	}

	&--facebook:hover {
		background-color: $c-facebookBlue;
	}

	&--twitter:hover {
		background-color: $c-twitterBlue;
	}

	&--pinterest:hover {
		background-color: $c-pinterestRed;
	}
}

.socialIcons + .richTextContent,
.richTextContent + .socialIcons {
	position: relative;

	&:before {
		content: '';
		border-top: 1px solid $c-cottonSeed;
		display: block;
		width: 100vw;
		position: absolute;
		top: 0;
		left: 50%;
		transform: translateX(-50%);
	}
}