.listings {
	@extend .clearfix;
	font-size: 0;
	text-align: center;
}

.listing {
	display: inline-block;
	vertical-align: top;
	width: 100%;
}

.listing--half {
	@include query_min($sidrBreakpoint){
		width: 50%;
	}
}

.listing--third {
	@include query_min($smallBreakpoint){
		width: 50%;
	}
	@include query_min($sidrBreakpoint){
		width: 33.33%;
	}
}

.listingLogo {
	padding: $paddingDefault*2 $paddingDefault;
	display: inline-block;
	height: 250px;
	width: 100%;
}

.listingLogoInner {
	display: block;
	@extend %centerBackground;
	background-size: contain;
	width: 180px;
	height: 180px;
	margin: 0 auto;
	max-width: 100%;
}

.listingImage, .listingContained {
	height: 200px;
	@include query_min($smallBreakpoint){
		height: 300px;
	}
	@include query_min($sidrBreakpoint){
		height: 372px;
	}
}

.listingImage {
	@extend %centerBackground;
	display: table;
	table-layout: fixed;
	width: 100.5%;
	//height: 100%;
}

.listingContained {
	@extend .listingLogo;
	position: relative;
}

.listingContainedInner {
	@extend %centerBackground;
	background-size: contain;
	width: 80%;
	height: 80%;
	position: absolute;
	top: 10%;
	left: 10%;
}

.listingText {
	@extend %lobotomize;
	padding: $paddingDefault;
	font-size: $fs-default;
	line-height: $lh-default;
	margin-bottom: $marginDefault;
	text-align: left;

	@include query_min( $smallBreakpoint ) {
		padding: $paddingDefault*1.5 $paddingDefault*2;
	}
}

.listingDisplayDate, .articleTitle {
	@include fontsize($fs-cardType);
	line-height: $lh-cardType;
	font-family: $ff-serif;
}

.listingBlurb + .buttonHolder {
	margin-top: $marginDefault;
}

//---------- Business Listings Overrides ----------
.listing--business {
	background: none !important;

	a {
		background-image: none;
		background-blend-mode: multiply;
		transition: background-color $transitionTime $transitionFunction;

		.listingLogoInner {
			display: block;
			transition: filter $transitionTime $transitionFunction;
			filter: grayscale(100%);
		}

		&:hover {
			background-color: $c-countryMeadow;
			.listingLogoInner {
				filter: grayscale(0) brightness(0) invert(1);
			}
		}
	}
}

//---------- Event Listings Overrides ----------
.listing--event, .listing--news {
	.listingText {
		* + * {
			margin-top: rems($marginDefault*.2);

		}
		text-align: center;
		padding-top: $paddingDefault *.5;

		@include query_min( $sidrBreakpoint ) {
			padding: $paddingDefault*.5 $paddingDefault*4 $paddingDefault;
		}
	}

	.buttonHolder {
		margin-top: $marginDefault;
	}
}

//---------- Floorplan Listings Overrides ----------
.listing--floorplan {
	.listingText {
		text-align: center;
		padding: $paddingDefault * .75;
		line-height: 1;
	}

	.listingBlurb {
		margin-top: $marginDefault*.5;
		@extend .spanDividers;
	}
}

//---------- Listings Lightbox ----------
.listingLightboxContent {
	@extend .clearfix;
	color: #fff;
	text-transform: uppercase;
	text-align: center;
	padding: $paddingDefault*2 $paddingDefault;

	p {
		margin-bottom: $marginDefault * 1.75;
		float: none;

		& > span {
			margin-left: $paddingDefault * .5;

			& + span {
				display: inline-block;
				padding: 0 $paddingDefault*.5 0 0;
				&:before {
					content: '|';
					margin-right: $paddingDefault*.5;
				}

				&:last-of-type:after {
					display: none;
				}
			}
		}
	}
}
.listing {
	.listingLightboxContent {
		display: none;
	}
}
