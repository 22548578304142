.brokerContactForm {
	border-top: 1px solid $c-cottonSeed;

	padding: $paddingDefault;

	@include query_min($smallBreakpoint) {
		padding: $paddingDefault*2 $paddingDefault;
	}

	form {
		max-width: $formBreakpoint;
		margin: 0 auto;
	}

	.no-js & {
		.checkboxLabel, .formAgentInfo {
			display: none;
		}
	}

	.checkboxLabel {
		margin-top: $marginDefault*.75;
	}
}

.brokerFormText,
.thanksMessageHolder {
	@extend .richTextContent--callout;
	text-align: center;

	@include query_min($smallBreakpoint) {
		padding: 0 $paddingDefault*1.5 $paddingDefault;
	}

	@include query_min($sidrBreakpoint) {
		width: 75%;
		margin: 0 auto;
	}
}

.formAgentInfo {
	margin-top: $marginDefault*1.25;
	display: none;
}

.thanksMessageHolder {
	text-align: center;
	padding-bottom: 0;
}