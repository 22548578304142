.richTextContent {
	@extend %lobotomize;
	@include fontsize($fs-default);
	line-height: $lh-default;

	padding: $paddingDefault;

	@include query_min($smallBreakpoint) {
		padding: $paddingDefault*1.5 $paddingDefault*1.5;
	}

	@include query_min($sidrBreakpoint) {
		padding: $paddingDefault*3 0; // $paddingDefault*8;
		width: 75%;
		margin: 0 auto;
	}

	//turn links into buttons -- forced to implement -- hopefully temporary
	a {
		@extend .button--content;
	}

	ul, ol {
		padding-left: $paddingDefault*2;
	}

	p + ul, p + ol {
		margin-top: $marginDefault*.5;
	}

	li + li {
		margin-top: $marginDefault*.5;
	}

	ul li {
		list-style-type: disc;

		li {
			list-style-type: square;
		}
	}

	ol li {
		list-style-type: decimal;

		li {
			list-style-type: lower-alpha;
		}
	}
}

.richTextContent--callout {
	@include fontsize($fs-richTextCallout);
	line-height: $lh-richTextCallout;
	font-family: $ff-serif;
}