.clusterCarousel {
	background-color: $c-riverBed;
}

//---------- Full Slider ----------
.fullSlider {
	a.slide {
		display: block;
		text-decoration: none;
		transition: opacity $transitionTime*2 linear;
	}

	.slide {
		position: relative;
		@extend %centerBackground;
		display: none;

		&:nth-child(1) {
			display: block;
		}

		&:after {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			z-index: 1;
		}

		&.overlayLight {
			&:after {
				background-color: $c-osloGray;
				@include opacity(.8);
			}
		}

		&.overlayDark {
			&:after {
				background-color: $c-riverBed;
				@include opacity(.9);

				.bg-blend-mode & {
					@include opacity(.6);
				}
			}

			.bg-blend-mode & {
				background-color: $c-riverBed;
				background-blend-mode: multiply;
			}
		}

		&:hover {
			.playIcon {
				color: rgba(#fff,.3);
			}
		}
	}

	&.slick-slider {
		.slide {
			display: block;
		}
	}
}

.fullSlideContent {
	color: #fff;
	padding: $marginDefault*2 $marginDefault;
	position: relative;
	z-index: 2;

	@extend %lobotomize;

	@include query_min($sidrBreakpoint) {
		padding: $marginDefault * 4;
	}
}

.fullSlideHeading {
	font-family: $ff-serif;
	line-height: $lh-fullSliderHeading;
	@include fontsize($fs-fullSliderHeading * .6);
	@include query_min($sidrBreakpoint * .5) {
		@include fontsize($fs-fullSliderHeading * .8);
	}
	@include query_min($sidrBreakpoint) {
		@include fontsize($fs-fullSliderHeading);
	}
}

.fullSlideCaption {
	// font-family: $ff-serif;
	line-height: $lh-fullSliderCaption;
	@include fontsize($fs-fullSliderCaption*.8);
	@include query_min($sidrBreakpoint * .5) {
		@include fontsize($fs-fullSliderCaption * .9);
	}
	@include query_min($sidrBreakpoint) {
		@include fontsize($fs-fullSliderCaption);
	}
}

.fullSlideButton {
	@include fontsize($fs-fullSliderCaption*.8);
	@include query_min($sidrBreakpoint * .5) {
		@include fontsize($fs-fullSliderCaption * .9);
	}
	@include query_min($sidrBreakpoint) {
		@include fontsize($fs-fullSliderCaption);
	}
	outline: none;

	text-transform: uppercase;
	display: inline-block;

	.playIcon {
		@include fontsize($fs-fullSliderPlayIcon*.8);
		@include query_min($sidrBreakpoint * .5) {
			@include fontsize($fs-fullSliderPlayIcon * .9);
		}
		@include query_min($sidrBreakpoint) {
			@include fontsize($fs-fullSliderPlayIcon);
		}

		margin-right: $marginDefault;
	}
}