.page-content {
	@extend .fence-width;
}

.content {
	@extend .standard-width;
	background-color: #fff;
	min-height: 200px;
	color: $c-contentText;
	overflow: hidden;
}