// ---------- Map ----------
.sitePlanMap {
	@extend .clearfix;

	.buttonHolder {
		@extend .padded-content;
	}
}

.svgMapWrapper {
	padding: $paddingDefault;
	background-color: $c-desertStorm;
}

.svgMapWrapperInner {
	max-width: $svgMapMaxWidth;
	margin: 0 auto;
}

.svgMapContainer {
	position: relative;
	width: 100%;
	padding: 0 0 percentage(601/769);
	margin: 0;

	svg {
		width: 100%;
		height: 100%;
		vertical-align: top;
		position: absolute;
		top: 0;
		left: 0;
		border: 2px solid transparent;
		transition: border-color $transitionTime linear;

		&.svgBorder {
			border-color: $c-silverSand;
		}

		text {
			@extend %noSelect;
			font-family: $ff-sans !important;
			font-size: 10px !important;
			-webkit-font-smoothing: none !important;
		}

		.lot {
			cursor: pointer;

			&.noClick {
				cursor: default;

				polyline, rect {
					stroke-opacity: .65;
				}

				text {
					fill-opacity: .65;
				}
			}

			&.clickable {
				cursor: pointer;
			}
		}
	}
}

// ---------- Legend ----------
.sitePlanLegendWrapper {
	text-align: center;
	padding: $paddingDefault * 2 $paddingDefault;
}
.sitePlanLegend {
	display: inline-block;
	text-align: left;
}

.sitePlanLegendKey {
	display: block;

	& + .sitePlanLegendKey {
		margin-top: $marginDefault;
	}
}

.sitePlanLegendKeyColor {
	display: inline-block;
	vertical-align: middle;
	width: 25px;
	height: 25px;
	margin-right: $marginDefault*.5;
}

.sitePlanLegendKeyTitle {
	display: inline-block;
	vertical-align: middle;
	@extend .fauxH5;
	text-transform: uppercase;
}

// ---------- Floorplan Popover ----------
.svgMapPopover {
	position: absolute;
	top: 0;
	left: 0;
	border: 4px solid #fff;
	background-color: $c-cottonSeed;
	padding: $paddingDefault*.5;
	text-align: center;
	color: #fff;
	text-transform: uppercase;
	box-shadow: 0 5px 15px rgba(#000,.3);
	display: none;

	&:after {
		@extend %mg-font;
		content: $icon-triangle-down;
		color: #fff;
		@include fontsize(26px);
		position: absolute;
		bottom: rems(-27px);
		left: 50%;
		margin: 0 0 0 rems(-13px);
	}

	* + * {
		margin-top: rems($marginDefault*.5);

	}

	a {
		display: block;
		color: $c-riverBed;
		text-decoration: none;
		outline: none;
	}
}

.svgMapStatus {
	font-weight: bold;
}

.svgMapTitle {

}

.svgMapStats {
	@extend .spanDividers;
}

.svgMapLink {

}