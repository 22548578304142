.imageLinks {
	@extend .clearfix;
	font-size: 0;
	text-align: center;
	border-top: 1px solid $c-cottonSeed;
}

.imageLink {
	display: inline-block;
	@extend %centerBackground;
	background-size: auto;
	vertical-align: top;
	width:130px;
	height: 130px;
	margin: $marginDefault*.5 $marginDefault;
}