.blockRow {
	height: 200px;
	overflow: hidden;

	@include query_min($smallBreakpoint){
		height: 300px;
	}
	@include query_min($sidrBreakpoint){
		height: 400px;
	}
}