//---------- Default Style Overrides ----------
.mfp-bg {
	background: $c-shuttleGray;
}

.mfp-figure figure {
	padding: $paddingDefault*.5 $paddingDefault;
}

.mfp-content {
	font-family: $ff-sans;
	@include fontsize($fs-default);
	line-height: $lh-default;
}

.mfp-figure:after {
	display: none;
}

.mfp-wrap button.mfp-close {
	display: block;
	max-width: 100px;
	margin: $marginDefault auto;
	position: static !important;
	text-align: center !important;
	height: auto;
	color: #fff;
	@include opacity(1);

	span.icon-close {
		@include fontsize(24px);
		line-height: 1;
		display: block;
		margin-bottom: $marginDefault*.25;
	}

	.mfp-close-text {
		display: block;
		clear: both;
		line-height: 1;
		text-transform: uppercase;
		font-family: $ff-sans;
		@include fontsize(14px);
	}
}

.mfp-arrow {
	position: absolute;
	top: auto;
	bottom: $marginDefault*.25;
}

.mfp-arrow-left, .mfp-arrow-right {
	@include opacity(1);
	color: $c-contentText;
	margin: 0;
	padding: $paddingDefault;
	width: auto;
	height: auto;

	&:before, &:after {
		line-height: $lh-default;
		@include opacity(1);
		border: none;
		margin: 0;
		padding: 0;
		position: static;
		display: inline-block;
		vertical-align: middle;
		float: none;
		width: auto;
		height: auto;
	}
}

.mfp-arrow-left:before, .mfp-arrow-right:after {
	@extend %mg-font;
	@include fontsize(24px);
	display: inline-block;
	vertical-align: middle;
}

.mfp-arrow-left:after, .mfp-arrow-right:before {
	@include fontsize(14px);
	display: inline-block;
	vertical-align: middle;
	text-transform: uppercase;
	font-weight: bold;
	letter-spacing: 1px;
}

.mfp-arrow-left {
	left: $marginDefault;

	&:before {
		content: $icon-previous;
		@extend %mg-font;
	}

	&:after {
		content: 'Previous';
		margin-left: 5px;
	}
}

.mfp-arrow-right {
	right: $marginDefault;

	&:before {
		content: 'Next';
		margin-right: 5px;
	}

	&:after {
		content: $icon-next;
	}
}

//---------- Custom Listing Content Overrides ----------
.mfp-title {
	display: block;
	text-align: center;
	clear: both;
	@include fontsize(28px);
	margin-bottom: $marginDefault*1.5;
	font-family: $ff-serif;
	padding: 0;
	line-height: $lh-default;
	@extend .clearfix;
}

.mfp-listing {
	.mfp-content {
		max-width: $standardWidth;
	}
}